@import '../../variables';
@import '../../styles//material_icons_mixins.scss';

.top-nav {
  overflow-x: hidden;

  .navbar-brand {
    border-bottom: 1px solid $white;
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;

    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 500;
      color: $white;
    }
  }

  &.navbar,
  .offcanvas {
    background-color: tint-color($primary, 20%);

    .nav-item {
      margin: 0;
      width: 100%;
      padding: 0.5em;

      &:hover,
      &:focus,
      &.active {
        color: $white;
        background-color: tint-color($primary, 30%);
      }

      &.dropdown {
        &:hover,
        &:focus {
          background-color: unset;
        }

        @include media-breakpoint-up(md) {
          // On desktop, just open this dropdown inline
          .dropdown-menu {
            position: unset;
            background: unset;
            border: unset;
            min-width: 0;

            .dropdown-item {
              color: $white;

              &:hover,
              &:focus {
                color: $white;
                background-color: tint-color($primary, 30%);
              }
            }
          }
        }
      }
    }
  }

  .offcanvas {
    max-width: 80%;

    &-header {
      color: $white;
      border-bottom: 1px solid $white;


      h2 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        color: $white;
      }

    }

    &-body {
      display: flex;
      flex-direction: column;
    }
  }

  .nav-link {
    color: $white;
    display: flex;

    &:hover,
    &:focus {
      color: $white;
    }

    i {
      margin-right: 0.5em;
    }
  }

  .dropdown-toggle {
    margin: 0;

    &::after {
      display: none;
    }

    &::before {
      @include material-icons();
      content: 'expand_more';

      margin-right: 0.5em;
    }

    &[aria-expanded='true']::before {
      content: 'expand_less';
    }
  }

  .nav-item {
    &__todo-list {
      position: sticky;

      &__unread {
        margin-left: auto;
        font-size: 14px;
        background-color: $info;
        color: $white;
        padding: 2px;
        border-radius: 100px;

        .material-icons {
          font-size: 12px;
        }

        &.due-soon {
          background: unset;
          color: $warning;
        }
      }
    }
  }
}

// Move the navbar to the bottom on small screens
@include media-breakpoint-down(sm) {
  .top-nav.navbar {
    // .fixed-bottom
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;

    .nav-item {
      width: unset;
    }
  }
}

// Move the navbar to the side on large screens
@include media-breakpoint-up(md) {
  .top-nav.navbar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 160px;

    > .container-fluid {
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      padding: 0;
    }
  }

  .App.container {
    margin-left: calc(var(--bs-gutter-x) * 0.5 + 160px);
  }
}

// Ensure the app container has room for the bottom bar on mobile
@include media-breakpoint-down(sm) {
  .App {
    overflow-y: scroll;
  }

  #root {
    padding-bottom: 73px;
  }

  .top-nav {
    height: 73px;
  }
}

.mobile-back-button {
  position: absolute;
  top: 0;
  left: 0;
  // reserve enough height even if the back button is hidden
  height: 40px;
}
