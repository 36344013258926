@import '../../../../../variables';

$chm-gray: rgb(235, 238, 243);
$accordion-btn-bg: rgb(244, 237, 227);
$accordion-active-bg: rgb(244, 237, 227);
$accordion-active-color: rgb(8, 27, 62);

.submitToChm2024 {
  padding-bottom: 73px;
  min-height: 100vh;
  
  .accordion {
    --bs-accordion-btn-bg: rgb(244, 237, 227);
    --bs-accordion-active-bg: rgb(244, 237, 227);
    --bs-accordion-active-color: rgb(8, 27, 62);
    
    border-left: 2px solid rgb(216,92,81);
  }
  
  .accordion-button {
    @include media-breakpoint-down(sm) {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
    
    .badge {
      margin-left: auto;
    }
  }
  
  .accordion-item {
    margin-bottom: 0.5em;
  }
  
  .accordion-body {
    @include media-breakpoint-down(sm) {
      padding: 0.25em 0;
    }
    margin-left: 0.25em;
    margin-right: 0.25em;
    
    ol, ul {
      padding-left: 1em;
    }
  }
  
  .input-group {
    margin-bottom: 0.5em;

    .input-group-text {
      border: unset;
      background-color: unset;
      text-transform: unset;
      
      padding: 0;
      margin-right: 0.5em;
      width: 50%;
      text-wrap: wrap;
      text-wrap-mode: unset;
      text-align: left;
      
      @include media-breakpoint-down(sm) {
        width: 30%;
      }
    }
    
    &__long-text {
      .input-group-text {
        @include media-breakpoint-down(sm) {
          width: 66%;
        }
      }
    }
    
    .invalid-feedback {
      text-align: right;
    }
  }
    
  .form-control {
    border: 0;
    border-bottom: 1px solid;
    border-radius: 0;
    display: flex;
    align-items: center;
    padding-left: 0;

    &.disabled {
      background-color: unset;
    }
    
    &[type="date"] {
      // Align date input padding with the rest of the items
      margin-left: -4px;
      margin-right: -4px;
    }
  }

  textarea.form-control {
    border-bottom-width: 0;
  }
  
  .input-group-checkbox {
    .input-group-text {
      max-width: 66%;
    }
    
    .form-check {
      display: flex;
      align-items: center;
    }
  }
    
  .form-label {
    text-transform: unset;
  }
  
  .pre-existing-conditions,
  .is-add-on,
  .is-maternity-incident {
    background-color: $chm-gray;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    
    textarea.form-control {
      background-color: $chm-gray;
      resize: none;
      border-bottom-width: 1px;
    }
  }
  
  .consent-initials {
    display: flex;
    input {
      max-width: 33%;
      padding-left: 1em;
      border-bottom: 1px solid black;
    }
    label {
      flex: 1;
    }
  }
  
  .consent-disclaimer {
    // italicize;
    font-size: 0.8em;
    margin-top: 0.5em;
    font-style: italic;
  }
  
  button[type="submit"].btn-text {
    color: $cyan-500;
    display: flex;
  }
  
  .expenses-table-mobile,
  .expenses-table-desktop {
    
    .accordion-button {
      background-color: rgb(173, 144, 97);
      color: white;
      
      &::after {
        display: none;
      }
    }
    
    .charge-options-dropdown {
      &:not(.value-selected) {
        color: $text-muted;
      }
    }
    
    &__th,
    &__tr {
      text-align: center;
    }
    
    &__th {
      font-weight: bold;
      text-transform: uppercase;
    }
    
    &__tr {
      padding: 0;
      --table-border-color: #ecf0f1;
      
      &:nth-child(even) {
        background-color: $chm-gray;
        --table-border-color: #FFF;
      }
    }
    
    &__td {
      padding: 0.5em;
    }
    
    &__tr-group {
      display: flex;
      
      &__primary {
        .expenses-table-mobile__td {
          &:not(:first-child) {
            border-left: 1px solid;
            border-left-color: var(--table-border-color);
          }
        }
      }
      
      &__secondary {
        border-top: 1px solid var(--table-border-color);
        
        span {
          text-decoration: underline;
        }
      }
    }
  }
  
  .submitToChm2024__FloatingButtons {
    position: fixed;
    bottom: 1em;
    right: 1em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    
    button {
      color: white;
      border: 0;
      padding: 0.5em 1em;
      border-radius: 0.25em;
      margin-top: 0.5em;
    }
     
    .submitToChm2024__FloatingButtons-Home {
      background-color: $red-500;
    }
    .submitToChm2024__FloatingButtons-Next {
      background-color: $cyan-500;
    }
    
    @include media-breakpoint-down(sm) {
      bottom: 73px;
      flex-direction: row;
      
      button {
        margin-left: 0.5em;
      }
    }
  }
  
  .submitToChm2024-SubmissionOverview {
    &__form-status-item {
      margin-bottom: 1em;
      display: flex;
      
      .title {
        flex: 1;
        text-align: left;
      }
      
      .material-icons {
        font-size: 1em;
      }
      
      .badge {
        min-width: 2em;
      }
    }
    
    &__generate-documents-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      
      .spinner-border {
        height: 1em;
        width: 1em;
      }
    }
  }
  
  .tab-pane {
    border: 1px solid $nav-tabs-border-color;
    border-top: 0;
    padding: 0.5em;
    
    margin-bottom: 2em;
  }
}

.btn.btn-accordion {
  background-color: $accordion-btn-bg;
  color: $accordion-active-color;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
