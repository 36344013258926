@import '../../variables';

.checklist {
  padding: 0;
  
  @include media-breakpoint-down(sm) {
    // scroll past the bottom of the page on mobile
    > *:last-child {
      margin-bottom: 4em;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 0.5px solid $gray;
    padding: 0.5em;

    cursor: pointer;

    &.completed {
      background-color: rgba($gray, 0.1);
    }
    &.disabled {
      cursor: unset;
      pointer-events: none;
    }
    
    span {
      max-width: calc(100% - 16px);
      overflow: hidden;
      text-overflow: ellipsis;
      
      a {
        white-space: nowrap;
      }
    }
  }
}
