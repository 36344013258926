@import '../../../variables';

.edit-expense-form {

  .dropdown-menu {
    @include media-breakpoint-down(sm) {
      right: 0;
      width: unset;
      min-width: 100%;
    }
  }

  &__incident-input {
    .input-group-text {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .form-select {
      border-top-left-radius: 0.375rem !important;
      border-bottom-left-radius: 0.375rem !important;
    }
  }

  .form-select {
    &.disabled {
      color: $input-disabled-color;
      background-color: $input-disabled-bg;
      border-color: $input-disabled-border-color;
    }
  }
  
  .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 25%;
    
    @include media-breakpoint-down(md) {
      font-size: 0.8rem;
      width: 33%;
    }
  }
  
  
  &__form-check-wrapper {
    padding: 0.25em 1.5em;
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
