@import '../../../../../variables';

.submit-to-chm-2023 {
  .submit-to-chm__document {
    display: flex;
    justify-content: space-between;
    margin: 0.5em;
  }

  .chonky {
    &-chonkyRoot {
      max-height: 40vh;
    }
  }

  .submit-to-chm__actions {
    margin: 0.5em;

    button {
      margin-left: 1em;
    }
  }
  
  .submit-to-chm__file-browser {
    margin-bottom: 1em;
  }
  
  .submit-to-chm__next-steps {
    .nav-tabs {
      .nav-link.active {
        color: $orange;
        
        &:focus,
        &:hover {
          color: lighten($orange, 10%)
        }
      }
    }
    
    margin-bottom: 1em;
  }
  
  .tab-content {
    .tab-pane {
      padding: 1em;
      border: 1px solid $gray-300;
      border-top-color: transparent;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
