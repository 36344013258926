@import './variables';
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";

@import './styles/typography.scss';

@import './styles/material_icons';

.App {
  @include media-breakpoint-down(sm) {
    // Make sure we have room for the dynamic island & mobile back button
    padding-top: 40px;
    
    .offcanvas-header {
      margin-top: 40px;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes circle-rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.circle-rotate-animation {
  animation: circle-rotate-animation 1.5s infinite ease;
  transform-origin: center center;
}

.loading-bar {
  background-color: $secondary;
  height: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before {
    animation: loading-bar 2s linear infinite;
    background-color: $primary;
    content: '';
    display: block;
    height: 4px;
    left: -200px;
    position: absolute;
    width: 200px;
  }
}

@keyframes loading-bar {
  from {left: -200px;
    width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% { left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
}

@keyframes flicker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
  
  100% {
    opacity: 1;
  }
}

.flicker {
  animation: flicker 2.5s ease-in-out infinite;
}

a.editable {
  cursor: pointer;

  text-decoration: none;
  color: unset;

  .editable-placeholder {
    color: rgba($black, 0.3);
    font-size: 0.8rem;
  }
}

td.editable {
  cursor: pointer;

  border: 1px transparent;

  &:hover {
    border: 1px dashed $black;
  }

  a.editable {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.modal {
  max-width: 100vw;
}

.preview {
  canvas {
    max-width: 100%;
  }
}

.hr-thick {
  border-top: 2px solid $black;
}

.code {
  font-family: monospace;
  font-size: 0.8rem;
}

.a-block {
  text-decoration: none;
  color: unset;
  display: block;
  
  &:hover {
    color: unset;
  }
}

.driver-popover-footer {
  .driver-popover-next-btn {
    @extend .btn;
    @include button-outline-variant($primary);
    text-shadow: none;
  }
}
